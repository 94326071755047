<template>
  <div class="view-root view-content edit-note-container bg-white">
    <div class="flex justify-between items-center mb-6">
      <p class="title">My Refffs</p>
      <Avatar />
    </div>
    <div class="flex gap-6">
      <div class="edit-content-container">
        <div class="flex items-center justify-between">
          <div class="custom-btn back" @click="goBack">
            <img class="btn-icon" src="../../assets/back.png" />
            Back
          </div>
          <div class="flex items-center gap-2">
            <div class="custom-btn" @click="clickEditBtn" v-if="!isInEditMode">
              <i class="el-icon-edit" style="font-size: 16px" />
              Edit
            </div>
            <div class="custom-btn" v-if="isInEditMode" @click="clickEditBtn">
              <img class="btn-icon" src="../../assets/check.png" />Save
            </div>
            <div class="custom-btn delete-btn" @click="showDeleteNoteTips()">
              <img src="../../assets/icon_delete.png" style="object-fit: cover;width: 11px;height: 12px;">
              <!-- <i class="el-icon-delete btn-icon" style="font-size: 16px" /> -->
            </div>
          </div>
        </div>
        <div class="edit-note">
          <div class="flex items-center justify-between note-info">
            <div>
              {{
                $dayjs(cardNoteInfo.create_time * 1000).format("MMMM D, YYYY")
              }}
            </div>
            <div class="flex items-center gap-2">
              Reviews <img class="view-icon" src="../../assets/view.png" />{{
                cardNoteInfo.read_number
              }}
            </div>
          </div>
          <div class="note-input">
            <p v-if="!isInEditMode" class="note-title" v-html="decryptTitle"></p>
            <el-input v-else v-model="decryptTitle" class="note-title" :disabled="!isInEditMode" />

            <div class="flex items-center justify-between mb-4">
              <div class="tags-container">
                <div v-for="(tag, index) in labels.filter((i) => !!i)" class="tag" :key="index" :style="{
                  color: TagColorList[index].color,
                  background: TagColorList[index].background,
                  cursor: isInEditMode ? 'pointer' : 'auto',
                }" @click="deleteLabel(index)">
                  <div class="tag-text">{{ tag }}</div>
                </div>
              </div>
              <div class="custom-btn ml-6" style="height: 34px; border-radius: 8px; padding: 0 12px" v-if="isInEditMode"
                @click="showTagModal = true">
                <img src="../../assets/add.png" class="btn-icon" />
              </div>
            </div>
            <div class="note-content" v-if="isInEditMode == false" v-html="showContent"></div>
            <el-input v-else type="textarea"  v-model="decryptContent" autosize/>
          </div>
        </div>
      </div>
      <div class="timeline-container" v-if="remindTimes && remindTimes.length > 0">
        <p class="timeline-title">Wake-up mails</p>
        <div class="timeline-item-wrapper mt-6">
          <div class="flex items-center justify-between timeline-item" v-for="(item, index) in remindTimes" :key="index"
            :class="item.class">
            <div class="detail flex items-center">
              <!-- <div class="dot"></div> -->
              <el-checkbox :checked="item.push_status == 'yes'" :disabled="true" label="" style="margin-right: 12px"></el-checkbox>
              <p>{{ item.remind_label }}</p>
            </div>
            <p>{{ $dayjs(item.remind_time * 1000).format("MMM D, YYYY") }}</p>
          </div>
        </div>
      </div>
    </div>
    <Modal :show="showDeleteModal" @close="showDeleteModal = false">
      <div slot="title">Delete This Article</div>
      <div slot="content" class="delete-content">
        You are about to delete this article
      </div>
      <div slot="footer">
        <div class="w-full custom-btn" @click="onDelete">Delete</div>
      </div>
    </Modal>
    <Modal :show="showTagModal" @close="closeTagModal">
      <div slot="title">Add New Tags</div>
      <div slot="content" class="delete-content">
        <el-input v-model="tempTag" placeholder="Please input tag" />
      </div>
      <div slot="footer">
        <div class="w-full custom-btn" @click="addTag">Save</div>
      </div>
    </Modal>
    <Modal :show="showDeleteTag" @close="showDeleteTag = false">
      <div slot="title">Delete This Tag</div>
      <div slot="content" class="delete-content">
        Are you sure remove this tag?
      </div>
      <div slot="footer">
        <div class="w-full custom-btn" @click="onDelete">Delete</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getNote, initTags, updateNote, removeNote } from "../../api/reff";
import { decryptValue, encryptValue } from "../../utils/common";
import { Loading } from "element-ui";
import "../../assets/common/common.css";
import "../../assets/common/font.css";
import { getUserId } from "../../utils/store";
import Avatar from "../../components/Avatar.vue";
import Modal from "../../components/Modal.vue";
import { TagColorList } from "../../utils/common";

export default {
  name: "index",
  components: {
    Avatar,
    Modal,
  },
  data() {
    return {
      TagColorList,
      isInEditMode: false,
      id: "",
      noteInfo: {},
      cardNoteInfo: {
        create_time: "",
        read_number: 0,
      },
      showTime: "",
      decryptTitle: "",
      decryptContent: "",
      showContent:'',
      labels: [],
      showAddTagDialog: false,
      tempTag: "",
      loadingInstance: undefined,
      remindTimes: [],
      showDeleteModal: false,
      showTagModal: false,
      showDeleteTag: false,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.fetchData();
  },
  methods: {
    showDeleteNoteTips() {
      // this.$alert("Do you want to delete this note?", "Note", {
      //   confirmButtonText: "DELETE",
      //   callback: (action) => {
      //     if (action == "confirm") {
      //       removeNote(this.id).then((res) => {
      //         this.$message.success("Deleted");
      //         this.$router.go(-1);
      //       });
      //     }
      //   },
      // });
      this.showDeleteModal = true;
    },

    closeTagModal() {
      this.tempTag = "";
      this.showTagModal = false;
    },

    onDelete() {
      removeNote(this.id).then((res) => {
        this.$message.success("Deleted");
        this.showDeleteModal = false;
        this.$router.go(-1);
      });
    },

    formatBtnStyle() {
      let style = {};
      if (this.isInEditMode) {
        style.backgroundColor = "#FFC569";
        style.color = "white";
        style.borderColor = "#FFC569";
      } else {
        style.backgroundColor = "white";
        style.color = "#333333";
        style.borderColor = "#DCDFE6";
      }
      return style;
    },

    addTag() {
      if (this.tempTag == "") {
        this.$message.error("Please input");
        return;
      }
      this.labels.push(this.tempTag);
      this.tempTag = "";
      this.showTagModal = false;
    },

    deleteLabel(index) {
      if (this.isInEditMode == false) {
        return;
      }
      this.$alert("Are you sure remove this tag?", "Tips", {
        confirmButtonText: "Sure",
        callback: (action) => {
          if (action == "confirm") {
            this.labels.splice(index, 1);
          }
        },
      });
    },

    clickEditBtn() {
      this.isInEditMode = !this.isInEditMode;
      if (this.isInEditMode == false) {
        this.saveNote();
      }
    },
    async saveNote() {
      if (this.decryptTitle == "") {
        this.$message.error("Please input title");
        return;
      }
      if (this.decryptContent == "") {
        this.$message.error("Please input content");
        return;
      }
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: "Data submission in progress, please wait",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      let encryptTitle = encryptValue(this.decryptTitle);
      let encryptContent = encryptValue(this.decryptContent);
      let encryptLabels = [];
      if (this.labels.length > 0) {
        encryptLabels = this.encryptLabels(this.labels);
      } else {
        let aiTags = await initTags(this.content);
        let tempTags = aiTags.data.data;
        if (tempTags && tempTags != "") {
          let contents = tempTags.split("\n");
          let originalLabels = [];
          contents.forEach((item) => {
            let length = item.length;
            let start = item.indexOf(".") + 1;
            let tag = item.substring(start, length);
            originalLabels.push(tag.trim());
          });
          encryptLabels = this.encryptLabels(originalLabels);
        }
      }
      let reviewNote = "no";
      if (this.noteInfo.reviewNote) {
        reviewNote = this.noteInfo.reviewNote;
      }
      let params = {
        id: this.id,
        user_id: getUserId(),
        title: encryptTitle,
        content: encryptContent,
        reviewNote: reviewNote,
        labels: encryptLabels,
      };
      updateNote(params).then((res) => {
        this.loadingInstance.close();
        this.$message.success("Save success~");
        this.fetchData();
        this.isInEditMode = false;
      });
    },

    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getNote(this.id)
        .then((res) => {
          let result = res.data.data;
          this.cardNoteInfo = result;
          let des_content_new = result.des_content_new;
          this.decryptContent = this.doDecryptValue(des_content_new);
          this.showContent = this.decryptContent
          this.showContent = this.showContent.replaceAll('\n', '<br>')
          let des_title_new = result.des_title_new;
          this.decryptTitle = this.doDecryptValue(des_title_new);
          this.formatCreateTime();
          this.labels = [];
          for (let i = 0; i < result.des_labels_new.length; i++) {
            let label = this.doDecryptValue(result.des_labels_new[i]);
            this.labels.push(label);
          }
          if (result.reminds) {
            const reminds = result.reminds.sort(
              this.compare("remind_time", true)
            );
            this.remindTimes = reminds.map((obj) => {
              if (obj.push_status === "yes") {
                return {
                  ...obj,
                  class: "done",
                  remind_label: this.formatRemindPushedLabel(obj.remind_type),
                };
              } else if (reminds.find((o) => o.push_status === "no") === obj) {
                return {
                  ...obj,
                  class: "during",
                  remind_label: this.formatRemindPushedLabel(obj.remind_type),
                };
              } else {
                return {
                  ...obj,
                  class: "noStart",
                  remind_label: this.formatRemindPushedLabel(obj.remind_type),
                };
              }
            });
          }
        })
        .finally(() => loading.close());
    },
    compare(property, desc) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        if (desc === true) {
          // 升序排列
          return value1 - value2;
        } else {
          // 降序排列
          return value2 - value1;
        }
      };
    },
    formatRemindPushedLabel(remind_type) {
      switch (remind_type) {
        case "three_days":
          return "3 days";
        case "week":
          return "1 Week";
        case "month":
          return "1 Month";
        case "six_month":
          return "6 Months";
        case "one_year":
          return "1 year";
        case "two_year":
          return "2 years";
        case "four_year":
          return "4 years";
      }
    },
    formatCreateTime() {
      let create_time = this.cardNoteInfo.create_time;
      var date = new Date(create_time * 1000);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      this.showTime = this.formatMonthToEng(month) + " " + day + ", " + year;
    },
    doDecryptValue(encrypted) {
      return decryptValue(encrypted);
    },
    encryptLabels(labels) {
      let newArray = [];
      for (let i = 0; i < labels.length; i++) {
        let encryptLabel = encryptValue(labels[i]);
        newArray.push(encryptLabel);
      }
      return newArray;
    },
    goBack() {
      this.$router.push("/home/my-refffs");
    },
    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "Jan";
        case 2:
          return "Feb";
        case 3:
          return "Mar";
        case 4:
          return "Apr";
        case 5:
          return "May";
        case 6:
          return "Jun";
        case 7:
          return "Jul";
        case 8:
          return "Aug";
        case 9:
          return "Sep";
        case 10:
          return "Oct";
        case 11:
          return "Nov";
        case 12:
          return "Dec";
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec652b;
  border-color: #ec652b;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #ec652b;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
  background-color: #ec652b;
  border-color: #ec652b;
}

::v-deep .el-radio__input.is-checked+.el-radio__label {
  color: #ec652b;
}


.edit-note-container {
  padding: 32px;
  overflow-y: scroll;
  position: relative;

  .title {
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    color: #1a202c;
  }

  .timeline-container {
    width: 360px;
    height: fit-content;
    font-family: "Poppins";
    border: 1px solid #edf2f7;
    border-radius: 12px;
    padding: 24px;

    .timeline-title {
      font-size: 22px;
      font-weight: 600;
      color: #1a202c;
    }

    .timeline-item-wrapper {
      .timeline-item {
        &.done {
          color: #a0aec0;

          .detail {
            .dot {
              background: #a0aec0;
            }
          }
        }

        &.during {
          color: #ec652b;

          .detail {
            .dot {
              background: #ec652b;
            }
          }
        }

        font-size: 15px;
        margin-bottom: 12px;

        .detail {
          font-weight: 600;

          .dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            margin-right: 12px;
            background: #1a202c;
          }



        }
      }
    }
  }

  .edit-content-container {
    flex: 1;

    .edit-note {
      max-width: 1360px;
      border-radius: 16px;
      border: 1px solid #edf2f7;
      padding: 24px;
      margin-top: 24px;
      min-height: calc(100vh - 202px);
    }

    .note-info {
      color: #005a64;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      .view-icon {
        width: 14px;
        height: 14px;
      }
    }

    .note-input {
      .note-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        font-family: "Poppins";
        color: #1a202c;
        margin: 16px 0;
        width: 100%;
      }

      .note-content {
        word-wrap: break-word;
        white-space:break-spaces;
        width: 100%;
        font-size: 14px;
        line-height: 26px;
        font-style: normal;
        font-weight: 500;
        font-family: Manrope;
        color: #718096;
        word-wrap: break-word;
        white-space:break-spaces;
      }

      &::v-deep .el-input__inner {
        width: 100%;
        border: none;
        outline: none;
        padding: 0;
        background: transparent;
        font-family: "Poppins";
        color: #1a202c;
        margin: 16px 0;
        font-weight: 600 !important;
      }

      &::v-deep .el-textarea__inner {
        word-wrap: break-word;
        white-space:break-spaces;
        min-height: 60vh !important;
        width: 100%;
        border: none;
        outline: none;
        padding: 0;
        font-size: 14px;
        line-height: 26px;
        font-style: normal;
        font-weight: 500;
        background: transparent;
        font-family: Manrope;
        color: #718096;
        cursor: auto !important;
      }
    }
  }

  .custom-btn {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
    color: white;
    font-family: Poppins;
    background: #ec652b;
    height: 48px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 600;

    .btn-icon {
      width: 16px;
      height: 16px;
    }

    &.delete-btn {
      // background: #fe5024;
      border: 1px solid #fe5024;
      background-color: white;
    }

    &.back {
      color: #ec652b;
      border: 2px solid #ec652b;
      height: 48px;
      background: white;
    }
  }
}

.delete-content {
  font-size: 14px;
  color: #a0aec0;
  margin: 24px 0;
  width: 100%;
  text-align: center;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .tag {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    border-radius: 8px;
    font-size: 12px;
    padding: 8px 12px;

    .tag-text {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
